import { reactive, computed, toValue } from "vue"
import { useAuth0 } from "@auth0/auth0-vue"
import axios from "axios"
import { chargeBeeEditBilling, chargeBeeEditPayment } from "@/chargebee.js"
import { toast } from "@/toast/index.js"
import { auth0 } from "@/auth0.js"

const normalize = (user) => {
  const unwrapped = toValue(user)
  const keyEndsWith = s => Object.keys(unwrapped).find(key => key.endsWith(s))
  const canHaveTrial = unwrapped[keyEndsWith("can_have_trial")]
  const roles = unwrapped[keyEndsWith("roles")]
  const identity = unwrapped[keyEndsWith("identities")]
  const useMfa = unwrapped[keyEndsWith("use_mfa")]
  const forceMfa = unwrapped[keyEndsWith("force_mfa")]
  const userWithoutCustomClaims = Object.keys(unwrapped)
    .filter(key => !key.startsWith("http"))
    .reduce((obj, key) => {
      obj[key] = unwrapped[key]
      return obj
    }, {})
  return {
    ...userWithoutCustomClaims,
    canHaveTrial,
    roles,
    identity: identity ? identity[0] : {},
    useMfa,
    forceMfa,
  }
}

const user = () => normalize(auth0.user)

const signupWithRedirect = () => auth0.loginWithRedirect({
  authorizationParams: {
    initial_screen: "signUp",
  },
})

const useAccount = () => {
  const loading = reactive({
    reset: false,
    billing: false,
    payment: false,
    mfa: false,
  })

  const { logout: _logout, isAuthenticated, user } = useAuth0()
  const normalizedUser = computed(() => normalize(user))

  const logout = () => {
    return _logout({
      logoutParams: {
        returnTo: `${window.location.origin}/`,
      },
    })
  }

  const resetPassword = async () => {
    try {
      loading.reset = true
      await axios.post("/customer/reset_password")
      toast.success(
        `We sent you an email to ${normalizedUser.value.email} so you can reset your password.`,
      )
    }
    catch (e) {
      toast.error(e)
    }
    finally {
      loading.reset = false
    }
  }

  const changeMfa = async (val) => {
    try {
      loading.mfa = true
      await axios.put("/customer/mfa", { mfa_flag: val })
      toast.success(`Multifactor authentication is now ${val ? "enabled" : "disabled"}.`)
      if (val) {
        // val === true will cause a logout because auth0 requires a new authentication
        await logout()
      }
    }
    catch (e) {
      toast.error(e)
    }
    finally {
      loading.mfa = false
    }
  }

  const editBilling = async () => {
    try {
      loading.billing = true
      await chargeBeeEditBilling()
    }
    // eslint-disable-next-line no-unused-vars
    catch (e) {
      toast.error("Sorry, we could not get your billing information.")
    }
    finally {
      loading.billing = false
    }
  }

  const editPayment = async () => {
    try {
      loading.payment = true
      await chargeBeeEditPayment()
    }
    // eslint-disable-next-line no-unused-vars
    catch (e) {
      toast.error("Sorry, we could not get your payment information.")
    }
    finally {
      loading.payment = false
    }
  }

  return {
    isAuthenticated,
    user: normalizedUser,
    loading,
    resetPassword,
    changeMfa,
    editBilling,
    editPayment,
    logout,
  }
}

export {
  useAccount,
  user,
  signupWithRedirect,
}
