import { computed, toValue } from "vue"
import axios from "axios"
import { useQuery } from "@tanstack/vue-query"
import { toast } from "@/toast"
import { queryClient } from "@/query.js"

const ruleSet = {
  manageSubscription: {
    role: /^(owner|direct_reseller|distributor)$/,
    type: "subscription",
  },
  manageInstance: {
    role: /^(owner|admin|direct_reseller|involved_reseller)$/,
    type: "subscription",
  },
  manageInvoices: {
    role: /^(owner|direct_reseller|distributor)$/,
    type: "subscription",
  },
  uploadFiles: {
    role: /^(owner|direct_reseller|involved_reseller)$/,
    type: "subscription",
  },
}

const fetchAllPermissionsQuery = {
  queryKey: ["fetchAllPermissions"],
  async queryFn() {
    try {
      const { data } = await axios.get("/authorization/permissions")
      return data
    }
    catch (e) {
      toast.error(e)
      return {}
    }
  },
  placeholderData: () => ({}),
}

const fetchAllPermissions = () => queryClient.fetchQuery(fetchAllPermissionsQuery)

const hasPermission = (permissions, permission, resourceId) => {
  const resource = permissions.resources?.find(x => x.resourceId === resourceId)

  if (!resource) {
    return false
  }

  const rule = ruleSet[permission] || {
    role: /ˆNO_PERMISSION$/,
    type: "NO_PERMISSION",
  }

  return resourceId === resource.resourceId
    && rule.role.test(resource.role)
    && rule.type === resource.resourceType
}

const usePermissions = (resourceId) => {
  const { data: permissions, isFetching } = useQuery(fetchAllPermissionsQuery)
  return {
    isFetching,
    hasPermission: computed(() => (permission, id = "") => hasPermission(
      permissions.value, toValue(permission), toValue(id) || toValue(resourceId),
    )),
  }
}

export {
  usePermissions,
  fetchAllPermissions,
  hasPermission,
}
