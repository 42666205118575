<script>
import { createNamespacedHelpers } from "vuex"

const { mapState, mapActions, mapMutations } = createNamespacedHelpers("subscription")

export default {
  name: "CSubscription",
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default: () => "",
    },
  },
  emits: ["fetched", "created", "cancelled"],
  data() {
    return {
      isFetching: this.$store.state.subscription.subscriptions.length === 0,
      isLoading: false,
    }
  },
  computed: {
    ...mapState(["subscriptions"]),
    normalizedSubscriptions() {
      const dateDifferenceInDays = (a, b) => {
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())
        const msPerDay = 1000 * 60 * 60 * 24
        return Math.floor((utc2 - utc1) / msPerDay)
      }

      return this.subscriptions.map(({
        paymentAt,
        expiresAt,
        subscriptionStatus,
        fqdn,
        stackId,
        totalDues,
        ...rest
      }) => ({
        ...rest,
        subscriptionStatus,
        fqdn,
        stackId,
        nextPayment: new Date(paymentAt * 1000).toLocaleDateString(),
        // now() is technically not needed, but makes mocking for unit testing easier
        trialDaysLeft: dateDifferenceInDays(new Date(Date.now()), new Date(expiresAt * 1000)),
        remainingDaysLeft: dateDifferenceInDays(new Date(Date.now()), new Date(expiresAt * 1000)),
        isCancelled: subscriptionStatus === "non_renewing",
        isTrial: subscriptionStatus === "in_trial",
        isActive: subscriptionStatus === "active",
        subdomain: fqdn?.match(/^[^.]+/)[0],
        totalDues: totalDues / 100 || null,
      }))
    },
    normalizedSubscriptionById() {
      return this.normalizedSubscriptions.find(({ id }) => id === this.id) || {}
    },
    getTrialSubscription() {
      return this.subscriptions.find(subscription => subscription.subscriptionStatus === "in_trial")
    },
    hasSubscriptions() {
      return this.subscriptions.length > 0
    },
    canHaveTrial() {
      return this.subscriptions.every(({ subscriptionStatus }) => subscriptionStatus !== "in_trial")
    },
    isComplete() {
      return this.normalizedSubscriptionById?.stackStatus === "ready"
    },
    inProgress() {
      return this.normalizedSubscriptionById?.stackStatus === "not_ready"
    },
    isFailed() {
      return this.normalizedSubscriptionById?.stackStatus === "failed"
    },
    inUnknown() {
      return !(this.inProgress || this.isComplete || this.isFailed)
    },
  },
  async created() {
    await this.fetchSubscriptions()
    if (this.id) {
      await this.pollSubscription({ id: this.id })
    }
    this.isFetching = false
    this.$emit("fetched", this.normalizedSubscriptions, this.normalizedSubscriptionById)
  },
  methods: {
    ...mapActions([
      "pollSubscription",
      "fetchSubscriptions",
      "cancelSubscription",
      "updateSubscription",
      "restoreSubscription",
    ]),
    ...mapMutations(["CLEAR_CACHE"]),
    ...mapActions({
      async createSubscription(dispatch, planSubdomainRegion) {
        this.isLoading = true
        if (await dispatch("createSubscription", planSubdomainRegion)) {
          this.$emit("created")
        }
        this.isLoading = false
      },
      async changeSubdomain(dispatch, subdomain) {
        this.isLoading = true
        await dispatch("changeSubdomain", {
          ...this.normalizedSubscriptionById,
          subdomain,
        })
        this.isLoading = false
      },
    }),
    async changeSubscription(pricingPlan, currentPricingPlan, cancelReasonCode) {
      const {
        planId,
        isCancelled,
        isActive,
        isTrial,
      } = this.normalizedSubscriptionById
      const canBeCancelled = pricingPlan?.id === planId && (isActive || isTrial)
      const canBeRestored = pricingPlan?.id === planId && isCancelled
      const canBeChanged = pricingPlan?.id !== planId

      this.isLoading = true
      // have to copy because cancelSubscription may remove an item if it is a trial
      // and this.normalizedSubscription will return an empty object after calling it
      const copy = { ...this.normalizedSubscriptionById }
      if (canBeCancelled) {
        if (await this.cancelSubscription({ ...pricingPlan, ...copy, cancelReasonCode })) {
          this.$emit("cancelled", copy)
        }
      }
      else if (canBeRestored) {
        await this.restoreSubscription(copy)
      }
      else if (canBeChanged) {
        await this.updateSubscription({
          ...pricingPlan,
          ...this.normalizedSubscriptionById,
          planId: pricingPlan?.id,
          oldPlan: currentPricingPlan,
          newPlan: pricingPlan,
        })
      }
      this.isLoading = false
    },
  },
  render() {
    return this.$slots.default({
      subscriptions: this.normalizedSubscriptions,
      subscriptionById: this.normalizedSubscriptionById,
      trialSubscription: this.getTrialSubscription,
      isLoading: this.isLoading,
      isFetching: this.isFetching,
      isComplete: this.isComplete,
      inProgress: this.inProgress,
      isFailed: this.isFailed,
      inUnknown: this.inUnknown,
      hasSubscriptions: this.hasSubscriptions,
      canHaveTrial: this.canHaveTrial,
      changeSubscription: this.changeSubscription,
      createSubscription: this.createSubscription,
      changeSubdomain: this.changeSubdomain,
      clearCache: this.CLEAR_CACHE,
    })
  },
}
</script>
